<template>
	<div class="container">
		<Header :flag="flag" />
		<div class="banner">
			 <div class="banner-content">
				 <div class="banner-title">
					 <span class="banner-sub-title">放心购，优新选，商城任您挑选</span>
					 <br/>
					 <span class="banner-sub-title1">点滴生活，不失品味，商城优品，尽显奢华</span>
					<br/>
					<div class="banner-qrcode">
					 <img src="../assets/tk_action_qrcode.jpg" width="150px" height="150px" />
					 <br/>
					 <span class="qr-sub-text">微信扫一扫入驻</span>
					</div>
					
				 </div>
			 </div>
		</div>
		<div class="main">
			<div class="sales-div">
				<div class="sales-title">
					<span class="sales-title-text">在做营销活动时你是否也遇到这个问题</span>
					<br/>
					<div class="server-border"></div>
				</div>
				<div class="sales-content">
					<div class="sales-box" v-for="item in news">
						<div class="middle-box-content">
							<div class="box-img">
								<img :src="item.image" />
							</div>
							<div class="content-div">
								<div class="box-title">
									{{item.title}}
								</div>
								<div class="box-content">
									{{item.intro}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="main2">
			<div class="server">
				<div class="server-title">
					我们为您提供的服务
				</div>
				<div class="server-content">
					<div class="server-box" v-for="item in scenes">
						<div class="box-img">
							<img :src="item.image" />
						</div>
						<div class="box-intro">
							<span class="title">{{item.title}}</span>
							<br/>
							<span class="intro">{{item.subTitle}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="industry">
			<div class="industry-title">
				适用行业
			</div>
			<div class="industry-content">
				<div class="industry-box" v-for="item in industry">
					<div class="box-icon" v-if="item.image!=null && item.image !=''"><img :src="item.image"/></div>
					<div class="box-intro">
						<span class="title">{{item.title}}</span>
						<br/>
						<span class="intro">{{item.intro}}</span>
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
	metaInfo: {
		title: '蓝莓科技-拓客活动-智能销售解决方案',
		meta: [
		  {
			name: 'keywords',
			content: '拓客活动,拓客引流,蓝莓拓客,智能销售解决方案'
		  },
		  {
			name: 'description',
			content: '蓝莓科技拓客活动是提供高效引流拓客活动方案，提供线上优质、专业的售前、售后服务，让客户无后顾之忧。'
		  }
		]
	},
	name: 'TkAction',
	components: {
		Header,
		Footer
	},	
	data(){
		return{
		 flag:'solution',
		 news:[
			 {
				title: "营销活动多，成本投入高",
				intro: "没有测试就实施投入会产生很多问题，没有经过多次测试就进行发布，影响营销活动成功率。直接投入成本巨大，转化率低，投入产出不成正比。",
				image: 'https://file.lanmeihulian.com/tk_a_1.png'
			 },
			 {
				title: "随意跟风，失去自己的优势",
				intro: "现在很多行业内竞争激烈，存在供大于求的市场情况。产品太多，用户选择性大，如何在众多的选择中让用户选择自己？如果只是一味的模仿别人的做法，而没有分析这种做法是否适合自己的企业和产品，那么结果一般就是营销活动没有效果。",
				image: 'https://file.lanmeihulian.com/tk_a_2.png'
			 },
			 {
				title: "不了解产品，不了解用户",
				intro: "不了解的产品和用户，营销策略没有针对性和有效性，这是一个普遍的现象。很多营销人员在做营销活动时往往不关注产品和用户，不愿意花时间去深入了解产品和用户，而只是学习各种营销方法、技巧、模板等。最后，营销方案看起来很漂亮，但是没用。",
				image: 'https://file.lanmeihulian.com/tk_a_3.png'
			 }	 
		 ],
		 scenes:[
		 	{
		 		image:require('@/assets/img/sq_2.png'),
		 		title:"线上电商的服务",
		 		subTitle:"我们做了一个“活动商城”产品，帮商家开一个独立的自营商城，基于微信公众号、微信小程序、百度小程序、QQ、微博、快手等"
		 	},
		 	{
		 		image:require('@/assets/img/sq_1.png'),
		 		title:"门店电商服务",
		 		subTitle:"我们致力于帮门店做一套线上线下完整打通的经营系统，包含网上商城、网上营销、门店收银、全渠道进销存、全渠道统一会员等功能"
		 	},
		 	{
		 		image:require('@/assets/img/sq_6.png'),
		 		title:"营销推广服务",
		 		subTitle:"我们实施会员制促销，为会员提供全套营销模式，提供专业营销手段，开放销售平台提高会员销售业绩"
		 	},
		 	{
		 		image:require('@/assets/img/sq_5.png'),
		 		title:"高效的产品服务",
		 		subTitle:"我们对线上活动产品、线下活动产品、线上活动展示功能"
		 	},
		 	{
		 		image:require('@/assets/img/sq_4.png'),
		 		title:"优质客服服务",
		 		subTitle:"我们提供线上优质、专业的售前、售后服务，让客户无后顾之忧"
		 	},
		 	{
		 		image:require('@/assets/img/sq_3.png'),
		 		title:"后台管理服务",
		 		subTitle:"我们提供长久有效的后台管理，为客户上线产品、推广产品等进行统一规范管理。"
		 	},
			],
		 industry:[]
		}	
	},
	created() {
		this.getIndustry();
	},
	methods:{
		getIndustry(){
			this.$http.getIndustryList({type:3}).then(res => {
				if(res.data!=null){
					this.industry = res.data;
				}
			});
		}
	}
}
</script>

<style scoped lang="less">
.banner{
	 padding-top: 80px;
	 height: 450px;
	 background-image: url(https://file.lanmeihulian.com/banner_5.png);
	 background-repeat: no-repeat;
	 background-size:100% 100%;
	 .banner-content{
		 width: 1200px;
		 margin: auto;
		 .banner-title{
			 text-align: center;
			 .banner-sub-title{
				 display: inline-block;
				 margin-top: 100px;
				 font-size: 60px;
				 font-weight: 600;
				 color: #fdff38;
			 }
			 .banner-sub-title1{
				display: inline-block;
				margin-top: 20px;
				font-size: 28px;
				color: #ffffff; 
			 }
			 .banner-qrcode{
				 margin: 20px auto;
				 text-align: center;
				 height: 200px;
				 width: 400px;
				 .qr-sub-text{
					 font-size: 18px;
					 color: #ffffff; 
				 }
			 }
		 }
		 
	 }
}	
.main{
	width: 1200px;
	margin: auto;
	.sales-div{
		margin-top: 40px;
		height: 780px;
		.sales-title{
			text-align: center;
			.sales-title-text{
				font-size: 38px;
				font-weight: 600;
				line-height: 80px;
				text-align: center;
			}
			.server-border{
				width: 100px;
				background-color: #409EFF;
				height: 8px;
				border-radius: 5px;
				margin: auto;
			}
		}
		.sales-content{
			margin-top: 20px;
			display: flex;
			display: -webkit-flex;
			flex-direction: row;
			justify-content: space-between;
			.sales-box{
				height: 600px;
				width: 350px;
				.middle-box-content{
					width: 350px;
					margin:auto;
					.box-img{
						width: 350px;
						height: 300px;
						img{
							width: 350px;
							height: 300px;
						}
					}
					.content-div{
						margin:auto;
						width: 350px;
						height: 350px;
						background-color: #f7f7f7;
						.box-title{
							text-align: center;
							padding-top: 30px;
							font-weight: 600;
							line-height: 65px;
							font-size: 20px;
							color: #131313;
						}
						.box-content{
							width: 260px;
							margin: auto;
							text-align: left;
							font-size: 16px;
							line-height: 30px;
							color: #666666;
						}
					}
				}
			}
		}
	}
}	
.main2{
	background-color: #f7f7f7;
	.server{
		width: 1200px;
		margin: auto;
		margin-top: 113px;
		.server-title{
			padding-top: 73px;
			text-align: center;
			font-size: 32px;
			font-weight: 600;
			line-height: 50px;
			color: #131313;
		}
		.server-content{
			margin-top: 80px;
			display: -webkit-flex;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			.server-box{
				height: auto;
				width: 300px;
				margin: 10px 50px 10px 10px;
				padding-top: 20px;
				text-align: center;
				.box-icon{
					img{
						width: 50px;
						height: 50px;
					}
				}
				.box-intro{
					.title{
						font-size: 20px;
						font-weight:600;
						line-height: 65px;
						color: #131313;
					}
					.intro{
						font-size: 16px;
						line-height:30px;
						display: inline-block;
						width: 240px;
						padding-bottom: 40px;
						color: #666666;
						text-align: left;
					}
				}
			}	
			.server-box:hover{
				.box-intro{
					.title{
						color: #409EFF;
					}
				}
			}
		}	
	}
}
.industry{
	width: 1200px;
	margin: auto;
	margin-top: 60px;
	margin-bottom: 60px;
	.industry-title{
		text-align: center;
		font-size: 40px;
		font-weight: 600;
		line-height: 50px;
	}
	.industry-content{
		margin-top: 30px;
		display: -webkit-flex;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		.industry-box{
			height: 330px;
			width: 280px;
			margin: 10px 10px 10px 10px;
			text-align: center;
			background-color: #F2F2F2;
			.box-icon{
				height: 120px;
				width: 280px;
				margin: auto;
				display:table-cell;
				vertical-align: bottom;
				text-align: center;
				img{
					width: 50px;
					height: 50px;
				}
			}
			.box-intro{
				margin-top: 20px;
				.title{
					font-size: 24px;
					font-weight:600;
					line-height: 65px;
				}
				.intro{
					font-size: 16px;
					line-height:30px;
					display: inline-block;
					width: 240px;
					padding-bottom: 40px;
					text-align: left;
				}
			}
		}	
		.industry-box:hover{
			.box-intro{
				.title{
					color: #409EFF;
				}
			}
		}
	}	
}

</style>
